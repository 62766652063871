import type { Elm } from "./elm/Main";

export function setupPorts(app: Elm.Main.App): void {
  // Show list of partners when executing `partners()` in browser console
  ["partners"].forEach((fname) => {
    window[fname] = (): void => {
      app.ports.onConsoleCall.send(fname);
    };
  });

  // Trigger a native alert
  app.ports.alert.subscribe((message) => {
    alert(message);
  });

  // Write a log message to the console
  app.ports.log.subscribe((message) => {
    if (console) {
      console.log(message);
    }
  });

  // Write a log message to the console
  app.ports.logTable.subscribe((json) => {
    if (console) {
      console.table(json);
    }
  });

  app.ports.showPartnerLogo.subscribe(({ imageSrc, link }) => {
    const $image = document.getElementById("partnerLogo") as HTMLImageElement;
    const $link = document.getElementById(
      "partnerLogoLink"
    ) as HTMLAnchorElement;
    $image.src = imageSrc;
    $link.href = link;
    $link.toggleAttribute("hidden", false);
  });

  // Trigger the print dialog
  app.ports.print.subscribe(() => {
    window.print();
  });

  const lock = (event: Event): void => {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    event.returnValue = ""; // legacy browsers
  };

  // prevents leaving the page and loosing all your entered data (lock)
  app.ports.lock.subscribe(() => {
    if (!window["__isLocked"]) {
      window.addEventListener("beforeunload", lock);
      window["__isLocked"] = true;
    }
  });

  // remove lock
  app.ports.unlock.subscribe(() => {
    if (window["__isLocked"]) {
      window.removeEventListener("beforeunload", lock);
      window["__isLocked"] = false;
    }
  });

  app.ports.smoothScrollTo.subscribe((id) => {
    requestAnimationFrame(() => {
      const elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error(`Element #${id} not found for smoothScrollTo`);
      }
    });
  });

  app.ports.trackEvent.subscribe(([event, data]) => {
    const dataMap = data.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    if (window["dataLayer"]) {
      window["dataLayer"].push({ event, ...dataMap });
    } else {
      console.log("dataLayer", { event }, dataMap);
    }
  });

  // credits
  window["credits"] = (): string => {
    console.log(`
created 2020 by https://belza.digital, using:
* https://elm-lang.org
* https://parceljs.org
* https://tailwindcss.com
* https://firebase.google.com
* https://www.typescriptlang.org
* https://www.npmjs.com
* https://nodejs.org
* https://neovim.io
* https://getfirefox.com

`);
    return "OSS FTW!";
  };
}
