import "range-slider-element";

import { setupPorts } from "./ports";
import { Elm } from "./elm/Main";

(function main(): void {
  // when directly accessing www.vertreterdeckung.de redirect to /vertreter
  // to get the correct calculator
  if (
    location.hostname === "rechner.vertreterdeckung.de" &&
    (location.pathname === "" || location.pathname === "/")
  ) {
    location.href = "/vertreter";
  }

  /**
   * Redirects
   * =========
   * - /:AllRiskMichaelisCover -> /allrisk
   * - /:fpp                   -> /:fpplus
   */
  switch (location.pathname) {
    case "/:AllRiskMichaelisCover":
      location.href = "/allrisk";
      return;

    case "/:fpp":
      location.href = "/:fpplus";
      return;
  }

  /**
   * Parameter parsing
   * =================
   * - /              -> standard tariff group
   * - /allrisk       -> allrisk tariff group (default partner: "alltrad")
   * - /:vema         -> standard tariff group with partner "vema"
   * - /standard:vema -> standard tariff group with partner "vema"
   * - /allrisk:vema  -> allrisk tariff group with partner "vema"
   */
  const [, tariffGroup, , partner] =
    location.pathname.match(/^\/(\w+)?(:([\w-]+))?$/) || [];

  const app = Elm.Main.init({
    node: document.getElementById("app"),
    flags: {
      partner: partner || (tariffGroup === "allrisk" ? "alltrad" : null),
      tariffGroup: tariffGroup || "standard",
    },
  });
  setupPorts(app);
})();
